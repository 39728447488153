<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-toolbar-title class="ml-2 mt-2"
            >Relatório - Controle de Positivação</v-toolbar-title
          >
        </v-col>

		<v-col
          class="colunaHeader"
          cols="12"
          sm="12"
          md="2"
          lg="2"
          xl="2"
          align="end"
        >
          <v-btn class="ml-2 my-1" color="primary" @click="exportarresumoporloja">
            <v-icon left>mdi-microsoft-excel</v-icon> Resumo por loja
          </v-btn>
        </v-col>

        <v-col
          class="colunaHeader"
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
          align="end"
        >
          <v-btn class="ml-2 my-1" color="primary" @click="exportarhistorico">
            <v-icon left>mdi-microsoft-excel</v-icon> Histórico Execução de
            Campanhas
          </v-btn>
          <v-btn class="ml-2 my-1" color="primary" @click="exportar">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
          </v-btn>
          <v-btn class="ml-2 my-1" color="primary" @click="exportarAgrupadoLoja">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar por loja (agrup)
          </v-btn>
        </v-col>
      </v-row>
      <v-card class="mt-2 py-4 px-2" elevation="1">
        <v-row no-gutters>
          <!-- <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              v-model="filtro.dates"
              auto-select-first
              :items="allDates()"
              label="Mês/Ano"
              @change="filter()"
            ></v-autocomplete>
          </v-col> -->
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-text-field
              label="Data inicial"
              v-model="filtro.data_ini"
              @change="
                filtro.data_ini_prev = formatDate(filtro.data_ini);
              "
              outlined
              dense
              type="date"
              clearable
            ></v-text-field>
            <br />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-text-field
              label="Data Final"
              v-model="filtro.data_fim"
              @change="
                filtro.data_fim_prev = formatDate(filtro.data_fim);
              "
              outlined
              dense
              type="date"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              v-model="filtro.campanha"
              :items="items"
              :search-input.sync="search"
              hide-no-data
              hide-details
              label="Campanha"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              class="text-capitalize"
              :items="['raia', 'drogasil']"
              label="Bandeira"
              v-model="filtro.bandeira"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filtro.peca"
              :search-input.sync="search2"
              :items="items2"
              hide-no-data
              hide-details
              label="Peça"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filtro.filial"
              :items="items3"
              item-text="label"
              item-value="id_java"
              label="Filial"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-select
              outlined
              dense
              multiple
              v-model="group"
              :items="groupItems"
              label="Agrupador"
            ></v-select>
            <br />
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            cols="12"
            sm="6"
            md="5"
            lg="3"
            xl="3"
            aling="end"
            justify="end"
          >
            <v-btn
              class="ml-2 mb-1"
              color="primary"
              @click="
                filtro = {};
                filter();
              "
            >
              <v-icon left>mdi-filter-off</v-icon> Limpar
            </v-btn>
            <v-btn
              class="ml-2 mb-1"
              color="primary"
              @click="
                filter();
              "
            >
              <v-icon left>mdi-filter</v-icon> Filtrar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mt-2 py-4 px-2" elevation="1">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              aling="end"
              justify="end"
            >
              <v-card
                class="py-2 px-2"
                elevation="4"
                justify="center"
                align="center"
              >
                Qtd Filiais: {{ this.dados.totalFilial }}
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="5"
              lg="4"
              xl="4"
              aling="end"
              justify="end"
            >
              <v-card
                class="py-2 px-2"
                elevation="4"
                justify="center"
                align="center"
              >
                Qtd Campanhas: {{ this.dados.totalCampanhas }}
              </v-card>
            </v-col>
           
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              aling="end"
              justify="end"
            >
              <v-card
                class="py-2 px-2"
                elevation="4"
                justify="center"
                align="center"
              >
                Qtd Peças: {{ this.dados.totalPeca }}
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              aling="end"
              justify="end"
            >
              <v-card
                class="py-2 px-2"
                elevation="4"
                justify="center"
                align="center"
              >
                Qtd: {{this.dados.totalQtdcompletos}} <br>
                Execução  Completa: {{ this.dados.totalcompletos }}%
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              aling="end"
              justify="end"
            >
              <v-card
                class="py-2 px-2"
                elevation="4"
                justify="center"
                align="center"
              >
                Qtd: {{this.dados.totalQtdparcial}} <br>
                Execução  Parcial: {{ this.dados.totalparcial }}%
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              aling="end"
              justify="end"
            >
              <v-card
                class="py-2 px-2"
                elevation="4"
                justify="center"
                align="center"
              >
                Qtd: {{this.dados.totalQtdpendentes}} <br>
                Execução  Pendente: {{ this.dados.totalpendentes }}%
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2 py-4 px-2" elevation="1">
        <!-- ====================
            TABLE
            =================== -->

        <template v-if="dados.length > 0">
          <v-data-table
            :headers="headers"
            :items="dados"
            :items-per-page="5"
            :options.sync="options"
            class="elevation-1"
          >
          </v-data-table>
        </template>
        <template v-if="dados.length == 0">
          <div>Não foram encontrados nenhum resultado</div>
        </template>

        <!-- ====================
            TABLE
            =================== -->
      </v-card>
    </v-container>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>




<script>
import axios from "axios";
import XLSX from "xlsx";

export default {
  data: () => ({
    groupItems: ["data", "bandeira", "campanha", "filial", "peca"],
    group: ["data", "bandeira", "campanha", "filial", "peca"],
    dialog: false,
    snackbar: false,
    snackbarText: "",
    overlay: true,  
    pecas: [],
    campanhas: [],
    lojas: [],
    filtro: {},   
    novoCadastro: {},    
    search: null,
    search2: null,
    search3: null,
    items: [],
    items2: [],
    items3: [],
    options: {},
    editando: {},
    dialogEditando: false,
    headers: [
      { text: "ID JAVA", value: "id_java", align: "center" },  
      { text: "VERSÃO ATUAL DO APP", value: "versao_app", align: "center" },  
      { text: "MÊS/ANO", value: "Mês/Ano", align: "center" },
      { text: "CAMPANHA", value: "campanha", align: "center" },
      { text: "FILIAL", value: "filial", align: "center" },
      { text: "BANDEIRA", value: "bandeira", align: "center" },
      { text: "PEÇA", value: "Peça", align: "center" },
      { text: "POSITIVADO", value: "positivado", align: "center" },
      { text: "NÃO POSITIVADO", value: "Não Positivado", align: "center" },
      { text: "PENDENTES", value: "Pendentes", align: "center" },
      { text: "REALIZADOS", value: "Realizados", align: "center" },
      { text: "TOTAL", value: "TOTAL", align: "center" },
    ],
    dados: [],
    allData: [],
    positivacaoFilial: [],
  

  }),
  computed: {
     mesAtual() {
        return this.$moment().format("MM/YYYY")
     },
     diaInicialMesAtual() {
        return this.$moment().startOf('month').format("YYYY-MM-DD")
     },
      diaFinalMesAtual() {
          return this.$moment().endOf('month').format("YYYY-MM-DD")
      }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  
    allDates() {

      var dates = new Set();
      this.campanhas.forEach((val) =>
        dates.add(`${val.mes.toString().padStart(2, "0")}/${val.ano}`)
      );
     
  
      return Array.from(dates);
    },
    stringToDate(data) {
      let date = new Date(data);
      return {
        dia: date.getUTCDate().toString().padStart(2, "0"),
        mes: (date.getUTCMonth() + 1).toString().padStart(2, "0"),
        ano: date.getUTCFullYear(),
        datepicker: `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`,
        string: `${date.getUTCDate().toString().padStart(2, "0")}/${(
          date.getUTCMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${date.getUTCFullYear()}`,
        date,
      };
    },
    readerData(rawFile, sheet) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const selectedSheet = workbook.SheetNames[sheet];
          const worksheet = workbook.Sheets[selectedSheet];
          // const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet);
          const meta = { sheetName: selectedSheet };
          resolve({ results, meta });
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    async xlstoidloja(xls) {
      const results = (await this.readerData(xls, 0)).results;
    
      if (results.length == 0) {
        this.snackbar = true;
        this.snackbarText = `não foram encontrados nenhum registro`;
        return [];
      }
  
      if (!(results[0].id_java != null) && !(results[0]["id java"] != null)) {
        this.snackbar = true;
        this.snackbarText = `È necessário ter o campo id java ou id_java`;
        return [];
      }
      const id_loja = results.map((val) => val.id_java || val["id java"]);
      return id_loja;
    },
    async novoCadastroXls(xls) {
      if (xls instanceof File) {
        const id_loja = await this.xlstoidloja(xls);
        const lojasEncontradas = id_loja.filter((val) =>
          this.lojas.find((loja) => loja.id == val)
        );
        const lojasNaoEncontradas = id_loja.filter(
          (val) => !this.lojas.find((loja) => loja.id == val)
        );
        if (lojasNaoEncontradas.length > 0) {
          this.snackbar = true;
          this.snackbarText = `não foram cadastradas as lojas com os seguintes id_java: ${lojasNaoEncontradas.join(
            ", "
          )}`;
        }
 
        const lojasEncontradasUnicas = new Set();
        lojasEncontradas.forEach((val) => lojasEncontradasUnicas.add(val));
        this.novoCadastro.id_loja = Array.from(lojasEncontradasUnicas);
      }
    },
    async editandoXls(xls) {
      if (xls instanceof File) {
        const id_loja = await this.xlstoidloja(xls);
        const lojasEncontradas = id_loja.filter((val) =>
          this.lojas.find((loja) => loja.id == val)
        );
        const lojasNaoEncontradas = id_loja.filter(
          (val) => !this.lojas.find((loja) => loja.id == val)
        );
        if (lojasNaoEncontradas.length > 0) {
          this.snackbar = true;
          this.snackbarText = `não foram cadastradas as lojas com os seguintes id_java: ${lojasNaoEncontradas.join(
            ", "
          )}`;
        }
        const lojasEncontradasUnicas = new Set();
        lojasEncontradas.forEach((val) => lojasEncontradasUnicas.add(val));
        this.editando.id_loja = Array.from(lojasEncontradasUnicas);
      }
    },
  
    pecaIDtoName(id) {
      return this.pecas.find((val) => val.id == id).nome;
    },
    lojaIDtoName(id) {
      return this.lojas.find((val) => val.id == id).descricao;
    },
 
  
    exportar() {

      const data = this.allData;

      var lojasPositivacao = [];

      this.positivacaoFilial.forEach((val) => {
        

        lojasPositivacao.push({
          'idjava': val.id_java,
          'filial': val.filial,
          'auditados': val.auditados,
          'pendentes': val.pendentes,
          'planejados': val.planejados, 
          '%execucao':   parseFloat(val.execucao).toFixed(2) * 100 ,
          'status': (parseFloat(val.execucao).toFixed(2) * 100) >= 100 ? 'COMPLETO' : 
            (parseFloat(val.execucao).toFixed(2) * 100) < 100 && (parseFloat(val.execucao).toFixed(2) * 100) > 0 ? 'PARCIAL' : 'PENDENTE' 
       
        });
      });
     



 
      const ws = XLSX.utils.json_to_sheet(data);
      const ws2 = XLSX.utils.json_to_sheet(lojasPositivacao);
      let wb = XLSX.utils.book_new();
  
      XLSX.utils.book_append_sheet(wb, ws, "Controle Positivação");
      XLSX.utils.book_append_sheet(wb, ws2, "Resumo por loja");
      XLSX.writeFile(wb, `controle_positivacao_${new Date().getTime()}.xlsx`);
    },
    exportarAgrupadoLoja(){
      // var lojasPositivacao = [];

  
  /// Agrupar e somar os valores por id_java e por mes
      console.log(this.positivacaoFilial);
  let lojasPositivacao = {};
  this.allData.forEach((val) => {
    const key = `${val.id_java}-${val['Mês/Ano']}-${val.bandeira}`;
    if (lojasPositivacao[key]) {
      lojasPositivacao[key].positivado += +val.positivado;
      lojasPositivacao[key]["Não Positivado"] += +val["Não Positivado"];
      lojasPositivacao[key].Pendentes += +val.Pendentes;
      lojasPositivacao[key].Realizados += +val.Realizados;
      lojasPositivacao[key].planejados += +val.Pendentes + +val.Realizados;
      
    } else {
      lojasPositivacao[key] = {
        id_java: +val.id_java,
        filial: val.filial,
        bandeira: val.bandeira,
        positivado: +val.positivado,
        ["Não Positivado"]: +val["Não Positivado"],
        Pendentes: +val.Pendentes,
        Realizados: +val.Realizados,
        planejados: +val.Pendentes + +val.Realizados,
        mes: val['Mês/Ano'],
        versao_app: val.versao_app,
      };
    }

  });
  const data =  Object.values(lojasPositivacao).map((val) => {
    return {
      versao_app: val.versao_app,
      id_java: val.id_java,
      mes: val.mes,
      filial: val.filial,
      bandeira: val.bandeira,
      positivado: val.positivado,
      ["Não Positivado"]: val["Não Positivado"],
      Pendentes: val.Pendentes,
      Realizados: val.Realizados,
      planejados: val.planejados,
    };
  }
  );

  console.log(data);

  const ws = XLSX.utils.json_to_sheet(data);
      // const ws2 = XLSX.utils.json_to_sheet(lojasPositivacao);
      let wb = XLSX.utils.book_new();
  
      XLSX.utils.book_append_sheet(wb, ws, "Positivação Agrupado Loja");
      // XLSX.utils.book_append_sheet(wb, ws2, "Resumo por loja");
      XLSX.writeFile(wb, `controle_positivacao_agrupado_loja_${new Date().getTime()}.xlsx`);


    },
  
    exportarresumoporloja() {

      var lojasPositivacao = [];

      this.positivacaoFilial.forEach((val) => {
        

        lojasPositivacao.push({
          'id_java': val['id_java'],
          'filial': val.filial,
          'mês / ano': val.mes_ano,
          'auditados': val.auditados,
          'pendentes': val.pendentes,
          'planejados': val.planejados, 
          '%execucao':   parseFloat(val.execucao).toFixed(2) * 100,
          'status': (parseFloat(val.execucao).toFixed(2) * 100) >= 100 ? 'COMPLETO' : 
            (parseFloat(val.execucao).toFixed(2) * 100) < 100 && (parseFloat(val.execucao).toFixed(2) * 100) > 0 ? 'PARCIAL' : 'PENDENTE' 
       
        });
      });
      console.log( this.positivacaoFilial)

     console.log(lojasPositivacao)



 
      const ws2 = XLSX.utils.json_to_sheet(lojasPositivacao);
      let wb = XLSX.utils.book_new();
  
      XLSX.utils.book_append_sheet(wb, ws2, "Resumo por loja");
      XLSX.writeFile(wb, `resumo_por_loja_${new Date().getTime()}.xlsx`);
    },
  async  exportarhistorico() {

    var self = this;
    self.overlay = true

  setTimeout( async() => {
    
   let historico = await axios.post( `${this.$SERVER_URL}campanha/historicocampanha`,this.allData,      
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        ).then(Response=>{
          return Response.data
        }).catch(erro=>{
          return erro
        }); 

      historico.map(item => {
        if(item.imagem){
          item.imagem = this.$assinarUrl(item.imagem)
        }
        return item
      })
     
      const ws = XLSX.utils.json_to_sheet(historico);  
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Histórico Execucação");
      XLSX.writeFile(wb, `historico_execucao${new Date().getTime()}.xlsx`);

      self.overlay=false
  }, 1000);

   
    },
    
    async filter() {

      this.overlay = true;
      const filter = {};
      this.campanhasFiltradas = this.campanhas;

      if (this.filtro.bandeira) filter.bandeira = this.filtro.bandeira;
      if (this.filtro.campanha) filter.campanha = this.filtro.campanha;
      if (this.filtro.peca) filter.peca = this.filtro.peca;
      if (this.filtro.filial) filter.filial = this.filtro.filial;
      if (this.filtro.dates)
        filter.mes = parseInt(this.filtro.dates.slice(0, 2), 10);
      if (this.filtro.dates)
        filter.ano = parseInt(this.filtro.dates.slice(3, 7), 10);
      if (this.filtro.data_ini) filter.dataInicial = this.filtro.data_ini;
      if (this.filtro.data_fim) filter.dataFinal = this.filtro.data_fim;
      const group = this.group.reduce((acc, cur) => {    acc[cur] = true; return acc; }, {});
  
      this.allData = (
        await axios.post(
          `${this.$SERVER_URL}adm/positivacao`,
          { filter, group },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },            

          }
        )
      ).data;

      
      this.positivacaoFilial = (
        await axios.post(
          `${this.$SERVER_URL}adm/positivacao/status`,
          { filter, group },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },            

          }
        )
      ).data;

      



    
      if (this.allData.length > 0) {
        this.headers = Object.keys(this.allData[0]).map(val => (   {
    
           text: val == 'versao_app' ? 'VERSÃO ATUAL DO APP' : val.toUpperCase(),
           align: "center" ,
          value: val
        }));
      }
      
      function mudarposicao(arr, from, to) {
          arr.splice(to, 0, arr.splice(from, 1)[0])
          return arr;
      }
      delete this.headers.id_campanha 
      this.headers =  mudarposicao(this.headers,0,7) // jogando a coluna versão_app para ultima posição

      this.dados = this.allData; 
      
       const campanhas =   this.dados.map(x => x.campanha).reduce( ( obj , acc ) => {  
       
          if ( !obj[acc] ) {
              obj[acc]=1;
          } else {
              obj[acc]++;
          }
          return obj;
        
        }, {} )
       const peca =   this.dados.map(x => x['Peça']).reduce( ( obj , acc ) => {  
            
          if ( !obj[acc] ) {
              obj[acc]=1;
          } else {
              obj[acc]++;
          }
          return obj;
        
        }, {} )

          
   

      
      const auditadosCompletos = this.positivacaoFilial.filter(x=>parseFloat(x["auditados"]) && parseFloat(x["execucao"]) >=1 ).reduce((obj,acc)=>{   return obj + parseFloat(acc["auditados"])  },0); 
      const auditadosParcial = this.positivacaoFilial.filter(x=>parseFloat(x["auditados"]) && parseFloat(x["execucao"]) > 0 && parseFloat(x["execucao"]) < 1 ).reduce((obj,acc)=>{return obj + parseFloat(acc["auditados"])},0);
      const planejados = this.positivacaoFilial.filter(x=>parseFloat(x["planejados"])).reduce((obj,acc)=>{return obj + parseFloat(acc["planejados"])},0);
      const pendentes = this.positivacaoFilial.filter(x=>parseFloat(x["pendentes"])).reduce((obj,acc)=>{ return obj + parseFloat(acc["pendentes"])},0);
      
      const QtdauditadosCompletos = this.positivacaoFilial.filter(x=>parseFloat(x["auditados"]) && parseFloat(x["execucao"]) >=1 )
      const QtdauditadosParcial = this.positivacaoFilial.filter(x=>parseFloat(x["auditados"]) && parseFloat(x["execucao"]) > 0 && parseFloat(x["execucao"]) < 1 )
      const Qtdpendentes = this.positivacaoFilial.filter(x=> parseFloat(x["execucao"]) <= 0 )


       this.dados.totalCampanhas =  Object.values(campanhas).length
       this.dados.totalPeca=  Object.values(peca).length
       this.dados.totalpendentes= (pendentes /planejados) ? (pendentes /planejados).toFixed(2) * 100 : 0
       this.dados.totalpendentes = Math.round(this.dados.totalpendentes)
       this.dados.totalcompletos=  (auditadosCompletos /planejados) ?  (auditadosCompletos /planejados).toFixed(2) * 100 : 0
       this.dados.totalcompletos = Math.round(this.dados.totalcompletos)
       this.dados.totalparcial=   (auditadosParcial /planejados) ? (auditadosParcial /planejados).toFixed(2) * 100 : 0
       this.dados.totalparcial = Math.round(this.dados.totalparcial)
    

       this.dados.totalQtdcompletos=  QtdauditadosCompletos ? Object.values(QtdauditadosCompletos).length : 0
       this.dados.totalQtdpendentes=  Qtdpendentes ? Object.values(Qtdpendentes).length :0
       this.dados.totalQtdparcial=  QtdauditadosParcial ?  Object.values(QtdauditadosParcial).length : 0

       this.dados.totalFilial = this.dados.totalQtdcompletos + this.dados.totalQtdpendentes + this.dados.totalQtdparcial;
      
       


      this.overlay = false;
    },
    querySelections(v) {
      this.items = [
        ...this.campanhas
          .map((val) => val.nome)
          .filter((e) => {
            return (
              (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          }),
        v,
      ];
    },
    querySelections2(v) {
      this.items2 = [
        ...this.pecas
          .map((val) => val.nome)
          .filter((e) => {
            return (
              (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          }),
        v,
      ];
    },
    querySelections3(v) {
    this.items3 = [
        ...this.lojas
          .sort((a, b) => (a.descricao > b.descricao ? 1 : -1))
          .map((val) => ({
           label: val.id_java + '-'+val.descricao,
           descricao : val.descricao,
           id_java: val.id_java
          }))
         .filter((e) => {
            return (
              (`${e.id_java}` || "").indexOf((`${v}` || "")) > -1
            );
          }),        
        v,
      ];


      
    },
  
    async CriarNovo(novoCadastro) {
      //const regexAll = /[^\\]*\.(\w+)$/;
      if (
        novoCadastro.bandeira &&
        novoCadastro.data_fim &&
        novoCadastro.data_ini &&
        novoCadastro.nome &&
        novoCadastro.observacao
      ) {
        this.dialog = false;
        this.overlay = true;
        if (this.novoCadastro.pdf) {
          const link = (
            await axios.post(
              `${this.$SERVER_URL}adm/sync/pdf`,
              {},
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Origin, Content-Type, X-Auth-Token",
                },
              }
            )
          ).data;
          const pdf = this.novoCadastro.pdf;
          await axios.put(link.uploadUrl, pdf, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
              "Content-Type": pdf.type,
            },
          });
          this.novoCadastro.pdf = link.downloadUrl;
        
        } else {
          this.novoCadastro.pdf = "";
        }
        this.campanhas = (
          await axios.post(`${this.$SERVER_URL}adm/campanha`, novoCadastro, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          })
        ).data;
        this.campanhas = this.campanhas.map((val) => ({
          ...val,
          data_ini: this.stringToDate(val.data_ini),
          data_fim: this.stringToDate(val.data_fim),
          peca: val.id_peca.map((val) => this.pecaIDtoName(val)),
          loja: val.id_loja.map((val) => this.lojaIDtoName(val)),
        }));
        this.filter();
        this.overlay = false;
      } else {
        this.snackbar = true;
        this.snackbarText = "É necessáro preencher todos os campos";
      }
    },
    async Editar(editando) {
      this.overlay = true;
      this.dialogEditando = false;
      if (editando.pdf instanceof File) {
        const link = (
          await axios.post(
            `${this.$SERVER_URL}adm/sync/pdf`,
            {},
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token",
              },
            }
          )
        ).data;
        const pdf = editando.pdf;
        await axios.put(link.uploadUrl, pdf, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": pdf.type,
          },
        });
        editando.pdf = link.downloadUrl;
     
      }
      const {
        bandeira,
        data_fim_a,
        data_ini_a,
        id,
        id_loja,
        id_peca,
        observacao,
        pdf,
      } = editando;
      this.campanhas = (
        await axios.put(
          `${this.$SERVER_URL}adm/campanha/${id}`,
          {
            bandeira,
            data_fim: data_fim_a,
            data_ini: data_ini_a,
            id_loja,
            id_peca,
            observacao,
            pdf,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        )
      ).data;
      this.campanhas = this.campanhas.map((val) => ({
        ...val,
        data_ini: this.stringToDate(val.data_ini),
        data_fim: this.stringToDate(val.data_fim),
        peca: val.id_peca.map((val) => this.pecaIDtoName(val)),
        loja: val.id_loja.map((val) => this.lojaIDtoName(val)),
      }));
      this.filter();
      this.overlay = false;
    },

    async Delete(id) {
      this.overlay = true;
      this.campanhas = (
        await axios.delete(`${this.$SERVER_URL}adm/campanha/${id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
      ).data;
      this.campanhas = this.campanhas.map((val) => ({
        ...val,
        data_ini: this.stringToDate(val.data_ini),
        data_fim: this.stringToDate(val.data_fim),
        peca: val.id_peca.map((val) => this.pecaIDtoName(val)),
        loja: val.id_loja.map((val) => this.lojaIDtoName(val)),
      }));
      this.filter();
      this.overlay = false;
    },


  },
  watch: {
    search(val) {
      val && val !== this.filtro.campanha && this.querySelections(val);
      this.filtro.campanha = val;
      this.filter();
    },
    search2(val) {
      val && val !== this.filtro.peca && this.querySelections2(val);
      this.filtro.peca = val;
      this.filter();
    },
     'filtro.filial'(val) {
      val && val !== this.filtro.filial && this.querySelections3(val);
      
      this.filter();
    },

 
  
  },


  async mounted() {

    this.lojas = (
      await axios.get(`${this.$SERVER_URL}adm/loja`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
    ).data;


    this.lojas = this.lojas.map((val) => ({
      ...val,
      label: val.id_java + " - " + val.descricao,
    }));

  
    this.pecas = (
      await axios.get(`${this.$SERVER_URL}adm/allPecas`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
    ).data;

    this.campanhas = (
      await axios.get(`${this.$SERVER_URL}adm/campanha`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
    ).data;

    this.campanhas = this.campanhas.map((val) => ({
      ...val,
      data_ini: this.stringToDate(val.data_ini),
      data_fim: this.stringToDate(val.data_fim),
      peca: val.id_peca.map((val) => this.pecaIDtoName(val)),
      loja: val.id_loja.map((val) => this.lojaIDtoName(val)),
    }));

    this.campanhasFiltradas = this.campanhas;



    this.filtro.dates = this.mesAtual
    this.filtro.data_ini = this.diaInicialMesAtual
    this.filtro.data_fim = this.diaFinalMesAtual

    

    this.filter();


    this.$store.commit("importacaoPecas/pecasJaCadastradas", {
      pecas: this.pecas,
    });

    this.querySelections("");
    this.querySelections2("");
    this.querySelections3("");
    
  },
};
</script>

<style>
</style>